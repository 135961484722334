@font-face {
  font-family: "Montserrat Alternates";
  src: url("../fonts/MontserratAlternates-SemiBold.woff2") format("woff2"),
    url("../fonts/MontserratAlternates-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Alternates";
  src: url("../fonts/MontserratAlternates-Regular.woff2") format("woff2"),
    url("../fonts/MontserratAlternates-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat Alternates";
  src: url("../fonts/MontserratAlternates-Medium.woff2") format("woff2"),
    url("../fonts/MontserratAlternates-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Elsie";
  src: url("../fonts/Elsie-Black.woff2") format("woff2"),
    url("../fonts/Elsie-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
