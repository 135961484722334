.who-we-are {
  .container {
    &:before,
    &:after {
      content: "";
      background-color: white;
      width: calc(100% + 10vw);
      height: 100%;
      top: 0;
      position: absolute;
      display: none;
      @screen xl {
        display: block;
      }
    }
    &:before {
      left: calc(-50% - 10vw + 111px);
      @screen 2xl {
        left: calc(-50% - 10vw + 79px);
      }
    }
    &:after {
      right: calc(-100% - 10vw);
      @screen xl {
        right: calc(-100% - 10vw + 16px);
      }
    }
  }
  &-bg {
    background: url("../../assets/images/who-we-are-bg.png") no-repeat;
    width: 562px;
    height: 416px;
    background-size: 514px;
    @screen xl {
      width: 562px;
      height: 416px;
      background-size: 514px;
    }
    @screen 2xl {
      background-size: 716px;
      width: 716px;
      height: 580px;
    }
    &:before,
    &:after {
      content: "";
      background-color: white;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    &:before {
      top: calc(-100% + 1px);
    }
    &:after {
      bottom: calc(-100% + 1px);
    }
  }
}
