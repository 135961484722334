.animated {
  animation-duration: 0.6s;
  animation-fill-mode: both;
}

@for $i from 1 through 9 {
  .animated-delay-#{$i} {
    animation-delay: #{$i}00ms;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.slideInUp {
  animation-name: slideInUp;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-delay: 0s;
  animation-name: fadeOutDown;
}

@keyframes minFadeLeft {
  from {
    opacity: 0;
    transform: translate3d(-200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.minFadeLeft {
  animation-name: minFadeLeft;
}

@keyframes minFadeRight {
  from {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.minFadeRight {
  animation-name: minFadeRight;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}
.zoomOut {
  animation-name: zoomOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}

@keyframes balloons {
  0% {
    transform: translateY(-16px) rotate(-1deg);
  }
  25% {
    transform: translateY(16px) rotate(1deg);
  }
  50% {
    transform: translateY(-16px) rotate(0deg);
  }
  75% {
    transform: translateY(16px) rotate(-1deg);
  }
  100% {
    transform: translateY(-16px) rotate(-1deg);
  }
}

.balloons {
  animation: balloons 13s ease-in-out infinite;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
.grapesZoomIn {
  animation-name: fadeInLeft;
  @screen sm {
    animation-name: zoomIn;
  }
}

@keyframes comingsoonFade {
  from {
    opacity: 0;
    bottom: -45px;
  }

  to {
    opacity: 1;
    bottom: -50px;
  }
}
