.club {
  [data-testid="layer-0"] {
    background-position: 80% center !important;
    @screen 2xl {
      background-position: right center !important;
    }
  }
  &:before {
    content: "";
    background: url("../../assets/images/stake-mask.png") no-repeat center top;
    width: 100%;
    height: 132px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    transform: rotate(180deg);
  }
}
