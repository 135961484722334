@tailwind base;

.roadmap {
  &-1 {
    background: url("../../assets/images/roadmap-bg1.jpg") no-repeat center top;
    background-size: 610px;
    @screen lg {
      background-size: 1150px;
    }
    @screen xl {
      background-size: auto;
    }
  }
  &-2 {
    background: url("../../assets/images/roadmap-bg2-m.png") no-repeat center
      163px;
    @screen lg {
      background: url("../../assets/images/roadmap-bg2.jpg") no-repeat center
        340px;
    }
  }
  &-num {
    span {
      $p: &;
      &:before {
        content: "";
        background: url("../../assets/images/roadmap-num-line.png") no-repeat;
        width: 228px;
        height: 2px;
        position: absolute;
        left: 50px;
        top: 44px;
        display: none;
        @screen 2xl {
          width: 338px;
        }
        @screen lg {
          display: block;
        }
      }
    }
    &:nth-child(4) {
      span:before {
        width: 160px;
      }
    }
    &:nth-child(5) {
      span:before {
        width: 172px;
      }
    }
    &:nth-child(8) {
      span:before {
        width: 102px;
      }
    }
    &:nth-child(9) {
      span:before {
        width: 172px;
      }
    }
  }
}

@layer base {
  .col-right {
    margin-left: auto;
    &:before {
      right: 50px !important;
      left: auto !important;
    }
  }
}
