//import tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

/** Config **/
@import "~normalize.css/normalize.css";
@import "./keyframes.scss";
@import "./fonts.scss";

/** Base **/
@layer base {
  html {
    @apply scroll-smooth;
    overflow-x: hidden;
  }
  body {
    font-size: 18px;
    color: #fff;
    overflow-x: hidden;
    min-width: 360px;
  }

  /** Gradient Color **/
  .gradient-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
  }

  .gradient-1 {
    background: linear-gradient(133.29deg, #202123 16.88%, #6b6c6d 114.96%);
  }

  .gradient-2 {
    background: linear-gradient(
      323deg,
      rgba(242, 237, 216, 0.35) 14.58%,
      #ffffff 90.87%
    );
  }
  .gradient-2-hover {
    background: linear-gradient(323deg, #f2edd8 14.58%, #e3e3e3 90.87%);
  }

  .gradient-3 {
    background: linear-gradient(
      76.17deg,
      rgba(188, 6, 3, 0.8) -12.38%,
      rgba(188, 6, 3, 0.4) 122.1%
    );
  }
  .gradient-3-hover {
    background: linear-gradient(
      76.17deg,
      #bc0603 -12.38%,
      rgba(188, 6, 3, 0.6) 122.1%
    );
  }
  .gradient-4 {
    background: linear-gradient(
      180deg,
      #ffffff -4.35%,
      rgba(255, 255, 255, 0.51) 144.2%
    );
  }
  .gradient-5 {
    background: linear-gradient(
      90.55deg,
      #ffffff -34.45%,
      rgba(255, 255, 255, 0.5) 121.41%
    );
  }
}
