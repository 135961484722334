header {
  min-width: 360px;
  &:before {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    height: 100%;
  }
  &:after {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.6) 100%);
    height: 0;
    backdrop-filter: blur(6px);
  }
  &.fixed-header {
    &:before {
      height: 20%;
    }
    &:after {
      height: 100%;
    }
  }
  .menu-item {
    &:after {
      top: -7px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      background: #b1000aab;
      z-index: -1;
    }
  }
}

.hover-eff {
  &:after {
    content: "";
    position: absolute;
    transition: transform 0.4s ease;
    transform: scaleX(0);
    transform-origin: bottom right;
  }
  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.mobile-menu-button {
  @apply relative w-8 h-8 block cursor-pointer;
  &:before,
  &:after,
  span {
    content: "";
    background: white;
    height: 2px;
    border-radius: 2px;
    right: 6px;
    transition: 0.35s;
    transition-timing-function: ease-in-out;
    display: block;
    position: absolute;
  }
  &:before {
    width: 7px;
    top: 9px;
  }
  span {
    width: 12px;
    top: 16px;
  }
  &:after {
    width: 16px;
    top: 23px;
  }
  &.active {
    &:before {
      width: 25px !important;
      transform: rotate(225deg);
      right: 2px;
      top: 16px;
      transition-delay: 0.15s;
    }
    span {
      width: 0 !important;
      opacity: 0;
    }
    &:after {
      width: 25px !important;
      transform: rotate(315deg);
      top: 16px;
      right: 2px;
      transition-delay: 0.15s;
    }
  }
  &:hover {
    &:before {
      width: 25px;
    }
    &:after {
      width: 23px;
    }
    span {
      width: 20px;
    }
  }
}

.main-menu {
  transform: translateX(-100%);
  &.open {
    transform: translateX(0) !important;
    transition: transform 0.6s;
  }
  @screen xl {
    transform: translateX(0);
  }
}

.coming-soon {
  $parent: &;
  &-pop {
    width: 111px;
    height: 45px;
    background: url("../../assets/images/coming-soon.png") no-repeat;
    position: absolute;
    bottom: -45px;
    left: calc(50% - 55.5px);
    opacity: 0;
    background-size: 111px;
    transition: 0.4s;
    animation-duration: 0.6s;
    animation-fill-mode: both;
    display: none;
  }
  &:hover {
    #{$parent}-pop {
      display: block;
      animation-name: comingsoonFade;
    }
  }
}
