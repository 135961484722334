.modal {
  &-close {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    @screen lg {
      right: 35px;
      top: 35px;
    }
    span {
      width: 15px;
      height: 3px;
      border-radius: 3px;
      position: absolute;
      background: white;
      transition: 0.5s;
      top: 13px;
      right: 7px;
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(135deg);
      }
    }
    &:hover {
      span {
        &:nth-child(1) {
          transform: rotate(315deg);
        }
        &:nth-child(2) {
          transform: rotate(225deg);
        }
      }
    }
  }
}
