.stake {
  background: url("../../assets/images//stake-bg.jpg") no-repeat center center /
    cover;
  &:before {
    content: "";
    background: url("../../assets/images/stake-mask.png") no-repeat center top;
    width: 100%;
    height: 132px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &-icon {
    width: 25px;
    height: 32px;
    position: relative;
    span {
      background: url("../../assets/images/barrel.svg") no-repeat;
      position: absolute;
      width: 25px;
      height: 32px;
      background-size: 25px;
      @screen lg {
        width: 45px;
        height: 56px;
        background-size: 45px;
      }
    }
    @screen lg {
      width: 45px;
      height: 56px;
    }
  }
  &-list {
    &-item {
      box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.2);
    }
  }
}
